import { createNamespacedHelpers } from 'vuex';

import { CLIENT_IDENTITY_DOMAIN } from '@errors/feature-domain-names';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import { META_ROBOTS_NOINDEX_NOFOLLOW } from '@configs/seo';
import { ORIGINAL_REFERRER_KEY } from '@configs/storage';

import { METRICS_BY_PAGES } from '@configs/page-performance';

import GTMExcluded from '@models/GTMExcluded/GTMExcluded';

import { CHECKOUT_SUCCESS_PAGE_NAME } from '@router/names';

import {
    hasLoyaltyClubStoreModule,
    registerLoyaltyStoreModule,
} from '@loyalty-club/helpers/store';
import { STORE_MODULE_NAME as LOYALTY_STORE_MODULE_NAME } from '@loyalty-club/store/meta';

import { PAGE } from '@analytics-types/Events';

import { MODULE_NAME as CLIENT_MODULE } from '@analytics-module/modules/client/meta';
import { USER_TYPE_STATUS_COMPLETE } from '@analytics-module/modules/client/types/Events';
import { MODULE_NAME as LOYALTY_CLUB_MODULE } from '@analytics-module/modules/loyalty-club/meta';
import { LOYALTY_CLUB_STATUS_COMPLETE } from '@analytics-module/modules/loyalty-club/types/Events';

import Deferred from '@core-assets/deferred';
import { getDeviceType } from '@assets/device';
import { startPerformanceMeasurement } from '@assets/performance';
import { isLoyaltyClubEnabled } from '@loyalty-club/assets';

import { insiderScripts } from '@modules/insider/assets/insider';

import WithCanonicalUrl from '@mixins/WithCanonicalUrl';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');
const {
    mapState: mapCustomerState,
    mapGetters: mapCustomerGetters,
} = createNamespacedHelpers('customer');
const { mapActions: mapAvatarActions } = createNamespacedHelpers('avatar');

export default ({ sendPageEvent = true } = {}) => ({
    data() {
        return {
            meta: {
                title: '',
                description: '',
                robots: META_ROBOTS_NOINDEX_NOFOLLOW,
            },
            responseStatusCode: 200,
            isPageEventEmittedDeferred: new Deferred(),
            isCustomerFetchedDeferred: new Deferred(),
            isPageEventSent: false,
        };
    },

    mixins: [WithCanonicalUrl],

    beforeCreate() {
        if (process.client) {
            const GTMExcludedProvider = new GTMExcluded(this.$storage);

            this.excludedCategories = GTMExcludedProvider.getCategoriesAnalyticsData();
            this.excludedProviders = GTMExcludedProvider.getProvidersAnalyticsData();
            this.areConsentsAccepted = GTMExcludedProvider.setAreConsentsAccepted();
        }
    },

    created() {
        if (process.client) {
            this.$analytics.waitPromise = this.isPageEventEmittedDeferred.promise;
        }
    },

    computed: {
        ...mapConfigGetters(['locale', 'currency', 'timezone', 'storeCode']),
        ...mapCustomerGetters([
            'customerUuid',
            'isLoggedIn',
            'customerHashedEmail',
            'userType',
        ]),
        ...mapCustomerState(['fetchingCustomerInProgress']),

        isLoyaltyClubEnabled() {
            return isLoyaltyClubEnabled(
                this.$abTests,
                this.storeCode,
                this.$cookies
            );
        },

        shouldSendPageEvent() {
            return sendPageEvent && !this.isPageEventSent;
        },
    },

    beforeRouteLeave(to, from, next) {
        const metricName = METRICS_BY_PAGES?.[to.name];

        if (metricName) {
            startPerformanceMeasurement(metricName);
        }

        this.$correlation.refreshIds();

        next();
    },

    watch: {
        fetchingCustomerInProgress: {
            async handler(fetchingInProgress) {
                if (fetchingInProgress) {
                    return;
                }

                this.isCustomerFetchedDeferred.resolve();
            },

            immediate: true,
        },
    },

    async beforeMount() {
        if (this.shouldSendPageEvent) {
            await this.emitPageEvent();
        }
    },

    mounted() {
        if (this.$route.name !== CHECKOUT_SUCCESS_PAGE_NAME) {
            insiderScripts();
        }

        this.handleCreateAndConnectAvatarId();
    },

    methods: {
        ...mapAvatarActions(['connectClient']),

        async handleCreateAndConnectAvatarId() {
            try {
                if (!this.$avatar.avatarIdFromCookies) {
                    this.$avatar.createAvatarId();
                }

                if (!this.$avatar.isNewAvatarId) {
                    return;
                }

                await this.isCustomerFetchedDeferred.promise;

                if (!this.isLoggedIn) {
                    return;
                }

                await this.connectClient(this.$avatar.avatarId);
            } catch (err) {
                this.$errorHandler.captureDomainError(
                    CLIENT_IDENTITY_DOMAIN,
                    err,
                    {
                        [ERROR_ACTION_TAG_NAME]:
                            'handleCreateAndConnectAvatarId',
                    }
                );
            }
        },

        async sendGAEventWithUserType() {
            await this.isCustomerFetchedDeferred.promise;

            this.$analytics.moduleEmit(
                CLIENT_MODULE,
                USER_TYPE_STATUS_COMPLETE,
                {
                    eventLabel: this.userType,
                }
            );
        },

        async sendGAEventWithLoyaltyClubStatus() {
            if (!this.isLoyaltyClubEnabled) {
                return;
            }

            let loyaltyClubStatus = null;
            let loyaltyClubPoints = null;

            await this.isCustomerFetchedDeferred.promise;

            if (this.isLoggedIn) {
                if (!hasLoyaltyClubStoreModule(this.$store)) {
                    await registerLoyaltyStoreModule(this.$store);
                }

                const data = await this.$store.dispatch(
                    `${LOYALTY_STORE_MODULE_NAME}/getLoyaltyMemberData`
                );

                const { status = null, activePoints = null } = data || {};

                loyaltyClubStatus = status;
                loyaltyClubPoints = activePoints;
            }

            this.$analytics.moduleEmit(
                LOYALTY_CLUB_MODULE,
                LOYALTY_CLUB_STATUS_COMPLETE,
                {
                    loyaltyClubStatus,
                    loyaltyClubPoints,
                }
            );
        },

        async emitPageEvent({
            route,
            searchData,
            responseStatusCode,
            pagePagination,
            treeCategory,
            syneriseCampaignIds,
        } = {}) {
            const originalReferrer = this.getOriginalReferrer();

            await this.$analytics.emit(
                PAGE,
                {
                    route: route || this.$route,
                    canonicalUrl: this.canonicalUrl,
                    locale: this.locale,
                    currency: this.currency,
                    robots: this.meta.robots,
                    searchData,
                    pagePagination,
                    deviceType: getDeviceType(),
                    responseStatusCode:
                        responseStatusCode || this.responseStatusCode,
                    locationHref: document.location.href,
                    consents: this.areConsentsAccepted,
                    gtmCookiesSettings: {
                        excludedCategories: this.excludedCategories,
                        excludedProviders: this.excludedProviders,
                    },
                    referrer: originalReferrer,
                    syneriseContainerIds: syneriseCampaignIds,
                    treeCategory,
                },
                true
            );

            if (originalReferrer) {
                this.removeOriginalReferrer();
            }

            this.isPageEventEmittedDeferred.resolve();

            this.isPageEventSent = true;

            if (this.$lux) {
                const { name = '' } = route || this.$route;

                if (name) {
                    this.$lux.label(name);
                }

                this.$lux.markLoadTime();
                this.$lux.readyToSend();
            }

            this.sendGAEventWithUserType();

            if (this.isLoyaltyClubEnabled) {
                this.sendGAEventWithLoyaltyClubStatus();
            }
        },

        getOriginalReferrer() {
            return this.$storage.getItem(ORIGINAL_REFERRER_KEY) || '';
        },

        removeOriginalReferrer() {
            this.$storage.removeItem(ORIGINAL_REFERRER_KEY);
        },
    },
});
